import axios from "axios";
import HelloSign from "hellosign-embedded";
import { DateTime } from "luxon";
import { checkResponseStatus } from "./globalUtils";
import { reactSelectStyles } from "../components/RentableStyles";

const DOCUMENT_CLIENT_ID = "6c07a991c5f700c2f456412baa613bdd";

const ALLOWED_FILE_UPLOAD_TYPES = ["jpg", "jpeg", "png", "pdf", "csv"];

const FILE_SIZE_LIMIT_MBS = 5;
const FILE_SIZE_LIMIT_BYTES = FILE_SIZE_LIMIT_MBS * 1000000;

const checkAllowedFileUpload = (file) => {
  if (!file) return;
  if (
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png" ||
    file.type === "application/pdf" ||
    file.type === "text/csv"
  ) {
    return true;
  } else {
    return false;
  }
};

const renderFileTypeError = () => {
  return (
    "Please make sure your file is one of the following types: " +
    ALLOWED_FILE_UPLOAD_TYPES.join(", ") +
    "."
  );
};

const checkFileSize = (file) => {
  if (!file) return;
  return file.size < FILE_SIZE_LIMIT_BYTES;
};

const handleDownloadW9Pdf = async (prepaymentUUID) => {
  const downloadW9Request = {
    prepayment_uuid: prepaymentUUID,
  };
  await axios
    .post("/api/documents/w9/download", downloadW9Request, {
      responseType: "blob",
    })
    .then((res) => {
      convertResponseBlobToDocumentDownload(res, "w9");
    })
    .catch((e) => {
      checkResponseStatus(e.status);
      throw new Error(e);
    });
};

const handleDocumentDownload = async (document_uuid, document_name) => {
  const documentRequest = {
    document_uuid: document_uuid,
    document_name: document_name,
  };
  await axios
    .post("/api/documents/download", documentRequest, {
      responseType: "blob",
    })
    .then((response) => {
      convertResponseBlobToDocumentDownload(response, document_name);
    })
    .catch((e) => {
      checkResponseStatus(e.status);
    });
};

const submitDocumentUpload = async (
  file,
  prepaymentUUID,
  currentUserId,
  isW9
) => {
  const documentData = new FormData();
  documentData.append("file", file);
  documentData.append("fileName", file.name);
  documentData.append("prepayment_uuid", prepaymentUUID);
  documentData.append("currentUserId", currentUserId);
  documentData.append("isW9", isW9);

  return axios
    .post("/api/documents/upload", documentData)
    .then((res) => {
      const data = res.data;
      return data.updated_prepayment;
    })
    .catch((e) => {
      checkResponseStatus(e.status);
    });
};

const submitDocumentDelete = async (uuid, prepaymentUUID) => {
  const documentDeleteRequest = {
    document_uuid: uuid,
    prepayment_uuid: prepaymentUUID,
  };
  return axios
    .post("/api/documents/delete", documentDeleteRequest)
    .then((res) => {
      const data = res.data;
      return data.updated_prepayment;
    })
    .catch((err) => {
      checkResponseStatus(err.status);
      console.log(
        "There was a problem deleting your document, please try again"
      );
    });
};

const downloadReceipt = async (deposit_uuid, type) => {
  const request = { deposit_uuid: deposit_uuid };
  axios
    .post("/api/documents/download/receipt", request, {
      responseType: "blob",
    })
    .then((response) => {
      const temp_date = DateTime.now().toFormat("yyyy-MM-dd");
      convertResponseBlobToDocumentDownload(
        response,
        `${type}_receipt_${temp_date}.pdf`
      );
    })
    .catch((e) => {
      checkResponseStatus(e.status);
    });
};

const downloadStatementOfCondition = () => {
  const pdfUrl =
    "https://www.masslegalhelp.org/sites/default/files/2024-01/LT%201_form%203_Example%20form_Statement%20of%20Condition.pdf";
  const link = document.createElement("a");
  link.href = pdfUrl;
  link.target = "_blank";
  link.download = "apartment-condition-statement.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadFeesReceipt = async (prepayment_uuid) => {
  const data = { prepayment_uuid: prepayment_uuid };
  axios
    .post("/api/documents/download/fee-receipt", data, {
      responseType: "blob",
    })
    .then((response) => {
      const temp_date = DateTime.now().toFormat("yyyy-MM-dd");
      convertResponseBlobToDocumentDownload(
        response,
        `fees_receipt_${temp_date}.pdf`
      );
    })
    .catch((e) => {
      checkResponseStatus(e.status);
    });
};

const downloadDeductionsReceipt = async (depositUUID) => {
  const request = { deposit_uuid: depositUUID };
  axios
    .post("/api/documents/download/deductions-statement", request, {
      responseType: "blob",
    })
    .then((response) => {
      convertResponseBlobToDocumentDownload(response, `deduction_summary.pdf`);
    })
    .catch((e) => {
      checkResponseStatus(e.status);
    });
};

const convertResponseBlobToDocumentDownload = (response, fileName) => {
  const blob = response.data;
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export {
  ALLOWED_FILE_UPLOAD_TYPES,
  FILE_SIZE_LIMIT_MBS,
  checkFileSize,
  handleDownloadW9Pdf,
  handleDocumentDownload,
  submitDocumentUpload,
  submitDocumentDelete,
  downloadReceipt,
  downloadStatementOfCondition,
  downloadFeesReceipt,
  downloadDeductionsReceipt,
  checkAllowedFileUpload,
  renderFileTypeError,
};
