export enum YardiPropertyTrackingTypes {
  IGNORED = 0,
  ENABLED_EMPTY = 1,
  POPULATED = 2,
}

export interface YardiPropertyShortInfo {
  uuid: string;
  name: string;
  code: string;
  street_address: string;
  tracking: YardiPropertyTrackingTypes;
}

export interface YardiCompanyDatabasesInterface {
  properties_qty: number;
  buildings: YardiPropertyShortInfo[];
  login: string;
  password: string;
  charge_name: string;
  url: string;
  deposit_account: string;
  disbursement_account: string;
  misc_income_account: string;
  default_bank_id: string;
  operating_month_field: string;
  uuid: string;
  note?: string;
  automatic_disbursement: boolean;
}

interface YardiDatabasesInterface {
  [key: string]: YardiCompanyDatabasesInterface;
}

export interface YardiIntegrationCompanyInfo {
  company_name: string;
  street_address: string;
  city: string;
  zip_code: string;
  state: string;
  manager_first_name: string;
  manager_last_name: string;
  yardi_company_email: string;
  yardi_company_code: string;
  properties_qty: string;
  company_comment: string;
  invite_link: string;
  yardi_db_name: YardiDatabasesInterface;
}

export interface IntegrationDetailsInterface {
  properties_qty: number;
  gl_accounts: [];
  yardi_company_info: any;
  invite_link: string;
  yardi_company_uuid: string;
  integration_details: any;
}

export interface YardiDatabaseOption {
  uuid: string;
  name: string;
}
