export const NUCLEUS_COLORS = {
  black: "#000",
  grey: "#222A3E",
  grey80: "#4E5464",
  grey60: "#7A7F8B",
  grey40: "#A7AAB2",
  grey20: "#D3D4D8",
  grey15: "#4f4f4f",
  grey10: "#E9EAEC",
  grey5: "#F4F4F5",
  grey2: "#F8F8F9",
  white: "#FFFFFF",
  danger: "#F13F4A",
  neutral0: "hsl(0, 0%, 100%)",
  neutral5: "hsl(0, 0%, 95%)",
  neutral10: "hsl(0, 0%, 90%)",
  neutral20: "hsl(0, 0%, 80%)",
  neutral30: "hsl(0, 0%, 70%)",
  neutral40: "hsl(0, 0%, 60%)",
  neutral50: "hsl(0, 0%, 50%)",
  neutral60: "hsl(0, 0%, 40%)",
  neutral70: "hsl(0, 0%, 30%)",
  neutral80: "hsl(0, 0%, 20%)",
  neutral90: "hsl(0, 0%, 10%)",
  primary: "#89ADB6",
  primary25: "#D7E4E7",
  success: "#155724",
  warning: "#EA9C29",
  backgroundTertiary: "#45585D",
  backgroundPrimary: "#266473",
  backgroundSecondary: "#8EACA6",
  backgroundDisabled: "#D3D4D8",
  nucleusYellow: "#D9D67E",
  nucleusOlive: "#93926A",
  nucleusRadioActiveGreen: "#C3FF5F",
  dropdownCaret: "#FFFFFF",
  backgroundWarning: "#FFEAAD",
  rentableGreen: "#1EB88D",
};
