// React and Hooks
import { useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import { updateCompanySection } from "../../../../../store/nucleusCompanies";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
// Styling
import styled from "styled-components";
// Interfaces
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { YardiCompanyDatabasesInterface } from "../interfaces/YardiIntegrationDetailsInterface";
//Components
import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import Input from "../../../../../components/baseComponents/Input";
import KeyValueRow from "../../../../../components/baseComponents/KeyValueRow";
import Modal from "../../../../../components/baseComponents/Modal";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import ToggleButton from "../../../../../components/baseComponents/ToggleButton";
// Utils
import { updateYardiDatabaseDetails } from "../../../../utils/yardiUtils";

const StyledTitle = styled(Div)`
  display: inline-block;
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  border-bottom: 3px solid ${(props) => props.theme.colors.primary};
`;

interface Props {
  modalOpen: boolean;
  setModalOpen: Function;
  dbName: string;
  databaseDetails: YardiCompanyDatabasesInterface;
}

const EditDatabaseDetailsModal = ({
  modalOpen,
  setModalOpen,
  dbName,
  databaseDetails,
}: Props) => {
  const dispatch = useAppDispatch();
  const [updatedDatabaseDetails, setUpdatedDatabaseDetails] =
    useState<GenericObject>({
      chargeName: databaseDetails.charge_name,
      url: databaseDetails.url,
      depositAccount: databaseDetails.deposit_account,
      disbursementAccount: databaseDetails.disbursement_account,
      miscIncomeAccount: databaseDetails.misc_income_account,
      defaultBankId: databaseDetails.default_bank_id,
      operatingMonthField: databaseDetails.operating_month_field,
      automaticDisbursement: databaseDetails.automatic_disbursement,
    });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { company } = useCompanyFromParams();

  const handleClose = () => {
    // ensure that database fields get reset on modal close
    setUpdatedDatabaseDetails({
      chargeName: databaseDetails.charge_name,
      url: databaseDetails.url,
      depositAccount: databaseDetails.deposit_account,
      disbursementAccount: databaseDetails.disbursement_account,
      miscIncomeAccount: databaseDetails.misc_income_account,
      defaultBankId: databaseDetails.default_bank_id,
      operatingMonthField: databaseDetails.operating_month_field,
      automaticDisbursement: databaseDetails.automatic_disbursement,
    });
    setError("");
    setSuccess("");
    setModalOpen(false);
  };

  const handleSave = () => {
    const successCallback = (updatedDatabase: GenericObject) => {
      setError("");
      // set updated database details on redux
      let integrationDetails = company["integration_details"];
      integrationDetails["yardi_db_name"][dbName] = updatedDatabase;
      dispatch(
        updateCompanySection(
          company.uuid,
          "integration_details",
          integrationDetails
        )
      );
      setSuccess("Successfully updated Yardi Database");
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    };
    const failureCallback = (errorMessage: string) => {
      setSuccess("");
      setError(errorMessage);
    };

    setError("");
    setSuccess("");
    updateYardiDatabaseDetails(
      databaseDetails.uuid,
      updatedDatabaseDetails,
      successCallback,
      failureCallback
    );
  };

  return (
    <Modal showModal={modalOpen} closeModal={handleClose} sizeVariant="medium">
      <Div width={{ default: 1 }} alignItems="center" mt={{ default: 2 }}>
        <StyledTitle
          mb={{ default: 4 }}
          ml={{ default: 2 }}
          width={{ default: 8 / 12 }}
          textAlign="center"
          pb={{ default: 3 }}
        >
          Edit Database: <i>{dbName}</i>
        </StyledTitle>
        <Div width={{ default: 8 / 12 }}>
          <KeyValueRow
            label="Login"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={databaseDetails.login}
                readOnly={true}
                backgroundColor="dark"
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Password"
            value={
              <Input
                heightVariant="tiny"
                defaultValue="**** ****"
                readOnly={true}
                backgroundColor="dark"
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Charge Name"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={updatedDatabaseDetails.chargeName}
                onChange={(e) => {
                  setUpdatedDatabaseDetails((details) => {
                    return { ...details, chargeName: e.target.value };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="URL"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={updatedDatabaseDetails.url}
                onChange={(e) => {
                  setUpdatedDatabaseDetails((details) => {
                    return { ...details, url: e.target.value };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Deposit Account"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={updatedDatabaseDetails.depositAccount}
                onChange={(e) => {
                  setUpdatedDatabaseDetails((details) => {
                    return { ...details, depositAccount: e.target.value };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Disbursement Account"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={updatedDatabaseDetails.disbursementAccount}
                onChange={(e) => {
                  setUpdatedDatabaseDetails((details) => {
                    return { ...details, disbursementAccount: e.target.value };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Miscellaneous Income Account"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={updatedDatabaseDetails.miscIncomeAccount}
                onChange={(e) => {
                  setUpdatedDatabaseDetails((details) => {
                    return { ...details, miscIncomeAccount: e.target.value };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Default Bank ID"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={updatedDatabaseDetails.defaultBankId}
                onChange={(e) => {
                  setUpdatedDatabaseDetails((details) => {
                    return { ...details, defaultBankId: e.target.value };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Operating Month Field"
            value={
              <Input
                heightVariant="tiny"
                defaultValue={updatedDatabaseDetails.operatingMonthField}
                onChange={(e) => {
                  setUpdatedDatabaseDetails((details) => {
                    return { ...details, operatingMonthField: e.target.value };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <KeyValueRow
            label="Automatic Disbursement"
            value={
              <ToggleButton
                initialToggleState={
                  updatedDatabaseDetails.automaticDisbursement
                }
                onToggle={() => {
                  setUpdatedDatabaseDetails((details) => {
                    return {
                      ...details,
                      automaticDisbursement: !details.automaticDisbursement,
                    };
                  });
                }}
              />
            }
            keyWidth={{ default: 6 / 12 }}
            valueWidth={{ default: 6 / 12 }}
          />
          <Div width={{ default: 1 }} mt={{ default: 4 }} alignItems="center">
            <Div width={{ default: 4 / 12 }} mb={{ default: 2 }}>
              <Button text="Save" onClick={handleSave} />
            </Div>
            {success && <SuccessMessage>{success}</SuccessMessage>}
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};

export default EditDatabaseDetailsModal;
