import React, { useRef, useEffect, useCallback } from "react";
import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components";
import { variant } from "styled-system";
import { COLORS } from "../global/colors";
import { cssBreakpoints } from "../global/theme";
import XButton from "./XButton";
import Div from "./Div";

const StyledBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

interface ModalProps {
  showModal?: boolean;
  variant?: string;
  sizeVariant?: string;
}

const StyledModal = styled(Div)<ModalProps>`
  width: 650px;
  z-index: 99;
  border-radius: 20px;
  ${cssBreakpoints("width", [{ "480px": "100%" }], "max-width")}
  ${cssBreakpoints("padding", [{ sm: "1rem" }, { md: "1rem 2rem" }])}
  ${variant({
    variants: {
      light: {
        background: COLORS.white,
      },
      dark: {
        background: COLORS.navy,
      },
    },
  })}
  ${variant({
    prop: "sizeVariant",
    variants: {
      small: {
        width: "50vw",
        height: "90vh",
      },
      medium: {
        width: "70vw",
        height: "90vh",
      },
      big: {
        width: "90vw",
        height: "90vh",
      },
    },
  })}
`;
const StyledCloseButton = styled(Div)<ModalProps>`
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999;
`;

interface KeyboardEvent {
  key: string;
}

interface Props {
  showModal: boolean;
  closeModal?: () => void;
  children: JSX.Element;
  variant?: string;
  sizeVariant?: string;
}

const Modal = ({
  showModal,
  closeModal,
  children,
  variant = "light",
  sizeVariant = "small",
}: Props) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const animation = useSpring({
    config: {
      duration: 275,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (modalRef.current === e.target && !!closeModal) {
      closeModal();
    }
  };

  const escapeKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && showModal && !!closeModal) {
        closeModal();
      }
    },
    [closeModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", escapeKeyPress);
    return () => document.removeEventListener("keydown", escapeKeyPress);
  }, [escapeKeyPress]);

  return (
    <>
      {showModal ? (
        <StyledBackground
          onClick={(e) => handleBackgroundClick(e)}
          ref={modalRef}
        >
          <animated.div style={animation}>
            <StyledModal
              showModal={showModal}
              alignItems="center"
              variant={variant}
              sizeVariant={sizeVariant}
            >
              {children}
              {!!closeModal && (
                <StyledCloseButton>
                  <XButton
                    color={variant == "light" ? COLORS.black : COLORS.white}
                    height="24"
                    width="24"
                    onClick={() => closeModal()}
                  />
                </StyledCloseButton>
              )}
            </StyledModal>
          </animated.div>
        </StyledBackground>
      ) : null}
    </>
  );
};

export default Modal;
