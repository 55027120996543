import axios from "axios";
import { GenericObject } from "../../components/global/ModelInterfaces";
import { checkResponseStatus } from "../../utils/globalUtils";

export const uploadYardiDepositCSV = async (
  csv: File,
  companyId: string | undefined,
  locationID: string | undefined,
  successCallback: Function,
  failureCallback: Function
) => {
  const csvData = new FormData();
  csvData.append("file", csv);

  const url = "/nucleus/api/yardi/yardi-deposits-import";
  axios
    .post(url, csvData, {
      headers: { company_id: companyId, location_id: locationID },
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err.data);
    });
};

// This function retrieves all the Yardi companies from the Rentable Company table
export const retrieveYardiCompanies = async (
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .get("/nucleus/api/yardi/yardi-get-companies")
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

// This function retrieves a single Yardi company details from the Rentable Company table
export const retrieveYardiCompany = async (
  companyUUID: string, // This is the UUID of the Yardi linked company on Rentable Company table
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .get("/nucleus/api/yardi/yardi-get-companies/" + companyUUID)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const retrieveYardiDbAccounts = async (
  yardiCompanyData: GenericObject,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-retrieve-db-accounts", yardiCompanyData)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const retrieveYardiNewProperties = async (
  dbName: string,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-retrieve-new-properties", dbName)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const createYardiCompany = async (
  yardiCompanyData: GenericObject,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-company-create", yardiCompanyData)
    .then((res) => {
      successCallback(res.data);
    })
    .catch((err) => {
      failureCallback(err);
    });
};

export const retrieveTenantLedger = async (
  tenantId: string,
  databaseName: string,
  companyCode: string,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-tenant-ledger", {
      tenant_id: tenantId,
      database_name: databaseName,
      company_code: companyCode,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      failureCallback(res.data.error);
    });
};

export const updateYardiDatabaseDetails = async (
  databaseUuid: string,
  databaseDetails: GenericObject,
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-database-update", {
      database_uuid: databaseUuid,
      charge_name: databaseDetails.chargeName,
      deposit_account: databaseDetails.depositAccount,
      disbursement_account: databaseDetails.disbursementAccount,
      misc_income_account: databaseDetails.miscIncomeAccount,
      default_bank_id: databaseDetails.defaultBankId,
      operating_month_field: databaseDetails.operatingMonthField,
      url: databaseDetails.url,
      automatic_disbursement: databaseDetails.automaticDisbursement,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      const internalServerError = checkResponseStatus(res.status);
      if (internalServerError) {
        failureCallback(internalServerError);
      } else {
        failureCallback(res.data.message);
      }
    });
};

export const yardiSelectPropertiesToTrack = async (
  databaseUuid: string,
  propertiesToTrack: string[],
  successCallback: Function,
  failureCallback: Function
) => {
  axios
    .post("/nucleus/api/yardi/yardi-update-property-tracking", {
      database_uuid: databaseUuid,
      property_uuids_to_track: propertiesToTrack,
    })
    .then((res) => {
      successCallback(res.data);
    })
    .catch((res) => {
      const internalServerError = checkResponseStatus(res.status);
      if (internalServerError) {
        failureCallback(internalServerError);
      } else {
        failureCallback(res.data.message);
      }
    });
};
