import styled from "styled-components";
import Row from "./Row";
import Div from "./Div";
import { ComponentBreakpoints } from "../global/ModelInterfaces";

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface KeyValueRowInterface {
  label: string;
  value: React.ReactNode;
  keyWidth?: ComponentBreakpoints | string;
  valueWidth?: ComponentBreakpoints | string;
}

/**
 * @component KeyValueRow
 *
 * This component displays a single key-value row.
 */
const KeyValueRow = ({
  label,
  value,
  keyWidth = undefined,
  valueWidth = undefined,
}: KeyValueRowInterface) => (
  <Row>
    <StyledLabel width={keyWidth} justifyContent="center">
      {label}:
    </StyledLabel>
    <Div width={valueWidth} justifyContent="center">
      {value}
    </Div>
  </Row>
);

export default KeyValueRow;
