import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../../store/hooks";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import PageLoader from "../../PageLoader";
import IntroCard from "./IntroCard";
import DocumentsCard from "./DocumentsCard";
import PaymentPlanCard from "./PaymentPlanCard";
import LeaseDetailsCard from "./LeaseDetailsCard";
import BankAccountCard from "./BankAccountCard";
import AdditionalFundsRequestCard from "./AdditionalFundsRequestCard";
import RentersInsuranceConfirmationCard from "./RentersInsuranceConfirmationCard";
import DisbursementInfoCard from "./DisbursementInfoCard";
import FeesTable from "./FeesTable";
import RenewalOfferCard from "./RenewalOfferCard";
import prepaymentStatuses from "../../../enums/prepaymentStatuses";
import { checkOnboardingComplete } from "../../../utils/tenantUtils";
import { hasPendingAdditionalFundsRequests } from "../../../utils/depositUtils";
import { GenericObject } from "../../global/ModelInterfaces";
import { COLORS } from "../../global/colors";
import { cssBreakpoints } from "../../global/theme";
import { RootState } from "../../..";
import useGetCurrentPrepayment from "../../../utils/useGetCurrentPrepayment";

const PrepaymentView = () => {
  const { prepaymentUUID } = useParams<GenericObject>();

  const { loadingTenantInfo, prepayment, onboarding, offboarding } =
    useGetCurrentPrepayment(prepaymentUUID);
  const tenantInfoSlice: { loading: Boolean; objects: GenericObject } =
    useAppSelector((state: RootState) => state.tenantInfo);
  const personalInfo = tenantInfoSlice.objects.personalInfo;

  const prepaymentInfo = prepayment;
  const security_deposit = prepayment?.deposits?.security_deposit;

  const needsOnboardingRedirect = () => {
    return !checkOnboardingComplete(onboarding);
  };

  if (!prepayment) {
    return (
      <Redirect
        to={{
          pathname: `/tenant/dashboard`,
        }}
      />
    );
  }

  if (offboarding && !offboarding.disbursed_at && !loadingTenantInfo) {
    return (
      <Redirect
        to={{
          pathname: `/tenant/offboarding/${prepayment.uuid}`,
        }}
      />
    );
  }

  if (onboarding && needsOnboardingRedirect() && !loadingTenantInfo) {
    return (
      <Redirect
        to={{
          pathname: `/tenant/onboarding/${prepayment.uuid}`,
        }}
      />
    );
  }

  const StyledDashboard = styled(Div)`
    height: 100vh;
    background-color: ${COLORS.grey2};
    ${cssBreakpoints("padding", [
      { sm: "1rem 0rem" },
      { md: "1.25rem" },
      { lg: "1.75rem" },
    ])}
  `;

  const StyledRequestCardContainer = styled(Div)`
    margin-top: 2rem;
  `;

  return (
    <StyledDashboard alignItems="center" width={{ sm: 1, md: 1, lg: 1 }}>
      {loadingTenantInfo ? (
        <PageLoader />
      ) : (
        <>
          {prepaymentInfo ? (
            <Row>
              <Div width={{ sm: 1, md: 1 / 3, lg: 2 / 3 }}>
                {security_deposit?.is_paid_monthly ? (
                  <Div>
                    <PaymentPlanCard
                      prepaymentInfo={prepaymentInfo}
                      personalInfo={personalInfo}
                    />
                  </Div>
                ) : (
                  <>
                    <LeaseDetailsCard
                      prepaymentInfo={prepaymentInfo}
                      onboardingInfo={onboarding}
                    />
                    {prepaymentInfo.fees.length > 0 && (
                      <FeesTable fees={prepaymentInfo.fees} />
                    )}
                  </>
                )}
                {[
                  prepaymentStatuses.DISBURSING,
                  prepaymentStatuses.CLOSED,
                ].includes(security_deposit?.status_id) &&
                  security_deposit?.disbursement_info && (
                    <DisbursementInfoCard
                      disbursementInfo={security_deposit.disbursement_info}
                    />
                  )}
              </Div>
              {prepaymentInfo.prepayment_uuid && (
                <Div width={{ sm: 1, md: 1 / 3, lg: 1 / 3 }}>
                  <DocumentsCard prepaymentInfo={prepaymentInfo} />
                  <BankAccountCard prepaymentInfo={prepaymentInfo} />

                  {hasPendingAdditionalFundsRequests(
                    prepaymentInfo.deposits
                  ) && (
                    <StyledRequestCardContainer>
                      <AdditionalFundsRequestCard
                        prepaymentUUID={prepaymentInfo.prepayment_uuid}
                      />
                    </StyledRequestCardContainer>
                  )}
                  {prepaymentInfo.renters_insurance_enrollment && (
                    <RentersInsuranceConfirmationCard
                      enrollment={prepaymentInfo.renters_insurance_enrollment}
                    />
                  )}
                  {security_deposit && security_deposit.eligible_renewal && (
                    <RenewalOfferCard />
                  )}
                </Div>
              )}
            </Row>
          ) : (
            <Div width={{ sm: 1, md: 2 / 3, lg: 2 / 3 }}>
              <IntroCard />
            </Div>
          )}
        </>
      )}
    </StyledDashboard>
  );
};

export default PrepaymentView;
