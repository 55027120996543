import { find, head } from "lodash";
import { useState, useEffect } from "react";

import Div from "../../../../../components/baseComponents/Div";
import Dropdown from "../../../../../components/baseComponents/Dropdown";
import Row from "../../../../../components/baseComponents/Row";
import { Option } from "../../../../../components/global/ModelInterfaces";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";

import { YardiCompanyDatabasesInterface } from "../interfaces/YardiIntegrationDetailsInterface";
import { YardiDatabaseOption } from "../interfaces/YardiIntegrationDetailsInterface";

interface Props {
  handleSelect: Function;
}

const DatabaseSelect = ({ handleSelect }: Props) => {
  const { company } = useCompanyFromParams();
  const databases: YardiDatabaseOption[] = Object.keys(
    company.integration_details.yardi_db_name
  ).map((key: string) => {
    const value: YardiCompanyDatabasesInterface =
      company.integration_details.yardi_db_name[key];
    return {
      name: key,
      uuid: value.uuid,
    };
  });
  const databaseOptions = databases.map((database: YardiDatabaseOption) => {
    return {
      label: database.name,
      id: database.uuid,
    };
  });
  const [selectedDatabase, setSelectedDatabase] = useState<
    YardiDatabaseOption | null | undefined
  >(head(databases));

  const selectDatabase = (option: Option) => {
    const database = find(databases, (db) => {
      return db.uuid === option.id;
    });
    setSelectedDatabase(database);
  };

  useEffect(() => {
    handleSelect(selectedDatabase);
  }, [selectedDatabase]);

  return (
    <Row>
      <Div width={{ default: 1 }}>
        <Dropdown
          options={databaseOptions}
          name="Database"
          placeholder="Choose a database"
          onClick={selectDatabase}
          value={
            selectedDatabase
              ? {
                  label: selectedDatabase.name,
                  id: selectedDatabase.uuid,
                }
              : null
          }
        />
      </Div>
    </Row>
  );
};
export default DatabaseSelect;
