import { size, some } from "lodash";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import { DetailsSectionInterface } from "../interfaces/DetailsSectionInterface";
import DatabaseDetails from "../components/SingleDatabaseDetails";

interface Props {
  yardiCompanyInfo: DetailsSectionInterface;
  initiallyOpen?: boolean | undefined;
}

/**
 * @component DatabaseInfoSection
 *
 * This component displays the details of a single database,
 * including the number of properties, used in the main DetailsSection component.
 **/
const DatabaseInfoSection = ({ yardiCompanyInfo, initiallyOpen }: Props) => {
  const databaseInfo =
    yardiCompanyInfo.integration_details?.yardi_db_name ?? {};

  return (
    <DropdownCard
      title={`Databases (${size(databaseInfo)})`}
      initiallyOpen={initiallyOpen || false}
    >
      {some(databaseInfo)
        ? Object.entries(databaseInfo).map(([dbName, details]) => (
            <DatabaseDetails
              key={dbName}
              dbName={dbName}
              databaseInfo={details}
            />
          ))
        : "Company Has No Databases"}
    </DropdownCard>
  );
};

export default DatabaseInfoSection;
