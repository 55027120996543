// React and Hooks
import { useState } from "react";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import useNucleusPermissions from "../../../../../utils/useNucleusPermissions";
// Styling
import BeatLoader from "react-spinners/BeatLoader";
import styled from "styled-components";
// Enums and Interfaces
import { nucleusUserPermissions } from "../../../../../enums/nucleusPermissions";
import { YardiCompanyDatabasesInterface } from "../interfaces/YardiIntegrationDetailsInterface";
import { retrieveYardiNewProperties } from "../../../../utils/yardiUtils";
// Components
import Button from "../../../../../components/baseComponents/Button";
import Div from "../../../../../components/baseComponents/Div";
import EditDatabaseDetailsModal from "../CompanyTabSections/EditDatabaseDetailsModal";
import EditDatabasePropertiesModal from "../CompanyTabSections/EditDatabasePropertiesModal";
import KeyValueRow from "../../../../../components/baseComponents/KeyValueRow";
import Row from "../../../../../components/baseComponents/Row";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
`;

const StyledSubTitle = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
  color: ${(props) => props.theme.colors.grey60};
`;

const StyledListItem = styled(Row)`
  background-color: ${(props) => props.theme.colors.grey10};
  border-radius: ${(props) => props.theme.border_radius.SM};
`;

const GreenText = styled.span`
  color: ${(props) => props.theme.colors.rentableGreen};
`;

const RedText = styled.span`
  color: ${(props) => props.theme.colors.danger};
`;

const Italic = styled.span`
  font-style: italic;
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

/**
 * @component SingleDatabaseDetails
 *
 * This component displays the details of a single database,
 * including the number of properties, used in the main DetailsSection component.
 *
 * @param dbName: string - The name of the Yardi database
 * @param databaseInfo: YardiCompanyDatabasesInterface - The parameters of the database
 * @returns
 */
const SingleDatabaseDetails = ({
  dbName,
  databaseInfo,
}: {
  dbName: string;
  databaseInfo: YardiCompanyDatabasesInterface;
}) => {
  const { loadingPermissions, integrationsAccess } = useNucleusPermissions();
  const [state, setState] = useState({
    editDetailsModalOpen: false,
    editPropertiesModalOpen: false,
    errorMessage: "",
    backendMessage: "",
    beatLoader: false,
    completed: false, // Check properties only once when successful. Avoid multiple checks for the same list.
    newBuildings: [] as YardiCompanyDatabasesInterface["buildings"],
  });
  const setField = (field: keyof typeof state, value: any) =>
    setState((prev) => ({ ...prev, [field]: value }));

  const {
    properties_qty,
    buildings,
    login,
    charge_name,
    url,
    deposit_account,
    disbursement_account,
    misc_income_account,
    default_bank_id,
    operating_month_field,
    automatic_disbursement,
    note,
  } = databaseInfo;

  const handleCallback = (propertyDetails: GenericObject) => {
    setField("backendMessage", propertyDetails.details?.note);
    setField("beatLoader", false);
    setField("completed", true);
    setField("newBuildings", propertyDetails.details?.buildings);
  };

  const handleError = (error: any) => {
    setField("errorMessage", error.data?.message || error.statusText);
    setField("beatLoader", false);
  };

  const checkForeMoreProperties = () => {
    /**
     * Retrieves the updated list of Yardi properties from a client's database
     **/
    setField("backendMessage", "");
    setField("errorMessage", "");
    setField("beatLoader", true);

    retrieveYardiNewProperties(dbName || "", handleCallback, handleError);
  };

  if (loadingPermissions) {
    return <BeatLoader />;
  }

  return (
    <>
      <Div mb={{ default: 3 }}>
        <Row>
          <Div width={{ default: 6 / 12 }} mb={{ default: 3 }}>
            <StyledTitle>{dbName}</StyledTitle>
            <StyledSubTitle>{properties_qty} properties</StyledSubTitle>
          </Div>
          {integrationsAccess === nucleusUserPermissions.WRITE && (
            <>
              <Div width={{ default: 2.5 / 12 }}>
                <Button
                  text="Edit Details"
                  onClick={() => {
                    setField("editDetailsModalOpen", true);
                  }}
                />
              </Div>
              <Div width={{ default: 2.5 / 12 }}>
                <Button
                  text="Edit Properties"
                  onClick={() => {
                    setField("editPropertiesModalOpen", true);
                  }}
                />
              </Div>
            </>
          )}
        </Row>
        <KeyValueRow
          label="Login"
          value={login}
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow
          label="Password"
          value="**** ****"
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow
          label="Charge Name"
          value={charge_name}
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow label="URL" value={url} keyWidth={{ default: 4 / 12 }} />
        <KeyValueRow
          label="Deposit Account"
          value={deposit_account}
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow
          label="Disbursement Account"
          value={disbursement_account}
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow
          label="Miscellaneous Income Account"
          value={misc_income_account}
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow
          label="Default Bank ID"
          value={default_bank_id}
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow
          label="Operating Month Field"
          value={operating_month_field}
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow
          label="Automatic Disbursement"
          value={
            automatic_disbursement ? (
              <GreenText>Enabled</GreenText>
            ) : (
              <RedText>Disabled</RedText>
            )
          }
          keyWidth={{ default: 4 / 12 }}
        />
        <KeyValueRow label="Note" value={note} keyWidth={{ default: 4 / 12 }} />

        <Row mt={{ default: 3 }}>
          {buildings && buildings.length > 0 ? (
            <>
              <StyledTitle mt={{ default: 24 }}>Properties:</StyledTitle>
              <Row>
                <Div width={{ default: 2.5 / 12 }} mb={{ default: 24 }}>
                  <Button
                    text="Import More Properties"
                    onClick={() => {
                      checkForeMoreProperties();
                    }}
                    disabled={state.completed || state.beatLoader}
                  />
                </Div>
              </Row>
              {state.beatLoader && <BeatLoader />}
              {state.errorMessage && (
                <ErrorMessage>{state.errorMessage}</ErrorMessage>
              )}
              {state.backendMessage && (
                <GreenText>{state.backendMessage}</GreenText>
              )}
              {state.newBuildings.length > 0 &&
                state.newBuildings.map((building) => (
                  <Row key={building.code}>
                    <StyledListItem
                      mt={{ default: 2 }}
                      pb={{ default: 1 }}
                      pt={{ default: 1 }}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Div display={{ default: "inline" }}>
                        <GreenText>New </GreenText>
                        <Bold>{building.name}</Bold> ({building.code}) --{" "}
                        {building.street_address}
                      </Div>
                      <Italic>...Importing</Italic>
                    </StyledListItem>
                  </Row>
                ))}
              {buildings.length > 0 &&
                buildings.map((building) => (
                  <Row key={building.uuid}>
                    <StyledListItem
                      mt={{ default: 2 }}
                      pb={{ default: 1 }}
                      pt={{ default: 1 }}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Div display={{ default: "inline" }}>
                        <Bold>{building.name}</Bold> ({building.code}) --{" "}
                        {building.street_address}
                      </Div>
                      {building.tracking ? (
                        <GreenText>Enabled</GreenText>
                      ) : (
                        <RedText>Disabled</RedText>
                      )}
                    </StyledListItem>
                  </Row>
                ))}
            </>
          ) : (
            <StyledLabel pt={{ default: 4 }}>
              Database Has No Properties
            </StyledLabel>
          )}
        </Row>
      </Div>
      {databaseInfo && (
        <EditDatabaseDetailsModal
          modalOpen={state.editDetailsModalOpen}
          setModalOpen={(isOpen: boolean) =>
            setField("editDetailsModalOpen", isOpen)
          }
          dbName={dbName}
          databaseDetails={databaseInfo}
        />
      )}
      {databaseInfo && databaseInfo.buildings && (
        <EditDatabasePropertiesModal
          modalOpen={state.editPropertiesModalOpen}
          setModalOpen={(isOpen: boolean) =>
            setField("editPropertiesModalOpen", isOpen)
          }
          databaseName={dbName}
          databaseUuid={databaseInfo.uuid}
          properties={databaseInfo.buildings}
        />
      )}
    </>
  );
};

export default SingleDatabaseDetails;
