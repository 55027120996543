// React and Hooks
import { useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import { updateCompanySection } from "../../../../../store/nucleusCompanies";
// Styling
import styled from "styled-components";
// Interfaces
import {
  YardiPropertyTrackingTypes,
  YardiPropertyShortInfo,
} from "../interfaces/YardiIntegrationDetailsInterface";
import { GenericObject } from "../../../../../components/global/ModelInterfaces";
// Components
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Checkbox from "../../../../../components/baseComponents/Checkbox";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import useCompanyFromParams from "../../../../utils/useCompanyFromParams";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import Modal from "../../../../../components/baseComponents/Modal";
// Utils
import { yardiSelectPropertiesToTrack } from "../../../../utils/yardiUtils";

const StyledTitle = styled(Div)`
  display: inline-block;
  font-size: ${(props) => props.theme.font_size.headlineSM};
  font-weight: ${(props) => props.theme.font_weight.bolder};
  border-bottom: 3px solid ${(props) => props.theme.colors.primary};
`;

const StyledListItem = styled(Row)`
  background-color: ${(props) => props.theme.colors.grey10};
  border-radius: ${(props) => props.theme.border_radius.SM};
`;

const StyledDivGrey = styled(Div)`
  display: inline;
  color: ${(props) => props.theme.colors.grey60};
  font-size: ${(props) => props.theme.font_size.bodyMD};
`;

const ScrollDiv = styled(Div)`
  overflow-y: scroll;
  max-height: 50vh;
`;

interface Props {
  modalOpen: boolean;
  setModalOpen: Function;
  databaseName: string;
  databaseUuid: string;
  properties: YardiPropertyShortInfo[];
}

const EditDatabasePropertiesModal = ({
  modalOpen,
  setModalOpen,
  databaseName,
  databaseUuid,
  properties,
}: Props) => {
  const dispatch = useAppDispatch();
  const initalTrackingProperties = properties
    .filter(
      (property) => property.tracking != YardiPropertyTrackingTypes.IGNORED
    )
    .map((property) => property.uuid);
  const [trackingProperties, setTrackingProperties] = useState(
    initalTrackingProperties
  );
  const [updatedProperties, setUpdatedProperties] = useState<GenericObject>({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { company } = useCompanyFromParams();

  const handleChange = (propertyUuid: string) => {
    setError("");
    setSuccess("");
    if (trackingProperties.includes(propertyUuid)) {
      setTrackingProperties(() =>
        trackingProperties.filter((property) => property != propertyUuid)
      );
    } else {
      setTrackingProperties(() => [...trackingProperties, propertyUuid]);
    }
  };

  const handleSave = () => {
    const successCallback = (res: GenericObject) => {
      setError("");
      setSuccess("Successfully updated Yardi Property Tracking");
      setTimeout(() => {
        setSuccess("");
      }, 3000);
      setUpdatedProperties(res);

      // set updated database details on redux
      let integrationDetails = company["integration_details"];
      let reduxProperties =
        integrationDetails["yardi_db_name"][databaseName]["buildings"];
      for (const reduxProperty of reduxProperties) {
        reduxProperty["tracking"] = res[reduxProperty.uuid]["tracking"];
      }
      integrationDetails["yardi_db_name"][databaseName]["buildings"] =
        reduxProperties;
      dispatch(
        updateCompanySection(
          company.uuid,
          "integration_details",
          integrationDetails
        )
      );
    };
    const failureCallback = (err: string) => {
      setTrackingProperties(initalTrackingProperties);
      setSuccess("");
      setError(err);
    };

    setSuccess("");
    setError("");
    if (
      trackingProperties.sort().join() == initalTrackingProperties.sort().join()
    ) {
      setError("No Changes Made");
    } else {
      yardiSelectPropertiesToTrack(
        databaseUuid,
        trackingProperties,
        successCallback,
        failureCallback
      );
    }
  };

  return (
    <Modal
      showModal={modalOpen}
      closeModal={() => {
        setModalOpen(false);
        setSuccess("");
        setError("");
        setTrackingProperties(initalTrackingProperties);
      }}
      sizeVariant="medium"
    >
      <Div width={{ default: 1 }}>
        <StyledTitle
          mb={{ default: 4 }}
          ml={{ default: 2 }}
          textAlign="center"
          pb={{ default: 3 }}
        >
          Edit Database Properties
        </StyledTitle>
        <ScrollDiv width={{ default: 1 }}>
          {properties.map((property) => (
            <Row key={property.uuid}>
              <StyledListItem
                mt={{ default: 2 }}
                pb={{ default: 1 }}
                pt={{ default: 1 }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Div>
                  <Div display={{ default: "inline" }}>
                    <b>{property.name}</b> ({property.code})
                  </Div>
                  {property.street_address}
                </Div>
                <Div display={{ default: "inline" }}>
                  {updatedProperties[property.uuid] && (
                    <StyledDivGrey mr={{ default: 3 }}>
                      <b>Units:</b> {updatedProperties[property.uuid]["units"]}
                    </StyledDivGrey>
                  )}
                  {property.tracking == YardiPropertyTrackingTypes.POPULATED ? (
                    "Locked"
                  ) : (
                    <Checkbox
                      value={trackingProperties.includes(property.uuid)}
                      onChange={() => {
                        handleChange(property.uuid);
                      }}
                    />
                  )}
                </Div>
              </StyledListItem>
            </Row>
          ))}
        </ScrollDiv>
        <Div width={{ default: 1 }} mt={{ default: 4 }} alignItems="center">
          <Div width={{ default: 3 / 12 }} mb={{ default: 2 }}>
            <Button text="Save" onClick={handleSave} />
          </Div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}
        </Div>
      </Div>
    </Modal>
  );
};

export default EditDatabasePropertiesModal;
