import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../baseComponents/Button";
import Div from "../../baseComponents/Div";
import Row from "../../baseComponents/Row";
import RentableHugCoin from "../../../images/RentableHugCoin";
import { GenericObject } from "../../global/ModelInterfaces";
import { cssBreakpoints } from "../../global/theme";

const PaymentPlan = styled(Div)`
  background-color: ${(props) => props.theme.colors.navy};
  border: 1px solid ${(props) => props.theme.colors.navy};
  color: ${(props) => props.theme.colors.white};
  border-radius: 1rem;
  padding: 1rem;
  font-size: ${(props) => props.theme.font_size.headlineXS};

  ${cssBreakpoints("height", [{ default: "auto" }, { lg: "100%" }])}
  ${cssBreakpoints("margin-bottom", [
    { sm: "1rem" },
    { md: "1rem" },
    { lg: "0" },
  ])}
`;
const Intro = () => {
  const history = useHistory();
  const { prepaymentUUID } = useParams<GenericObject>();

  return (
    <Div mt={{ default: 5 }}>
      <Row justifyContent="center">
        <PaymentPlan
          width={{ sm: 1, default: 6 / 12 }}
          justifyContent="center"
          alignItems="center"
        >
          <RentableHugCoin height="300" width="270" />
          <Div mt={{ default: 3 }} mb={{ default: 3 }}>
            Great news! Your Property Manager renewed your lease recently in
            Rentable and you may be eligible to access your funds today!
          </Div>
          <Button
            text="See Offer Details"
            onClick={() => {
              history.push(`/tenant/renewal-plan-details/${prepaymentUUID}`);
            }}
          />
        </PaymentPlan>
      </Row>
    </Div>
  );
};

export default Intro;
