import { useEffect, useState, useContext } from "react";
import { reactSelectStyles } from "./RentableStyles";
import BeatLoader from "react-spinners/BeatLoader";
import Select from "react-select";
import PlaidButton from "./PlaidButton";
import { ThemeContext } from "styled-components";
import { BankAccount } from "./global/ModelInterfaces";
import { useAppSelector } from "../store/hooks";
import { first } from "lodash";
interface Props {
  onSelectAccount: Function;
  disabled: boolean;
  defaultValue: BankAccount | undefined;
  defaultBankUUID?: String | null;
  bankAccounts?: BankAccount[];
}

interface Option {
  label: string;
  value: BankAccount;
}

const BankAccountSelect = ({
  onSelectAccount,
  disabled,
  defaultValue,
  defaultBankUUID,
  bankAccounts,
}: Props) => {
  const themeContext = useContext(ThemeContext);
  const bankInfo = useAppSelector((state) => state.bankInfo);
  const bankAccountList = bankAccounts ? bankAccounts : bankInfo.objects;

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Option[]>([]);

  const handleChange = (option: Option | null) => {
    onSelectAccount(option);
  };

  const findDefaultOption = () => {
    if (options.length == 1) {
      return first(options);
    } else {
      options.filter((option) => {
        return option.value === defaultValue;
      });
    }
  };

  useEffect(() => {
    setLoading(!bankAccountList);
    if (bankAccountList.length > 0) {
      const filteredAccounts = bankAccountList.filter((account: BankAccount) =>
        defaultBankUUID
          ? !account.pending_confirmation && account.uuid === defaultBankUUID
          : !account.pending_confirmation
      );
      const options = filteredAccounts.map((account: BankAccount) => ({
        label: `${account.bank_name} ${account.name} **** ${account.mask}`,
        value: account,
      }));
      setOptions(options);
    }
  }, [bankAccountList]);

  if (loading) return <BeatLoader />;

  return bankAccountList.length === 0 ? (
    <PlaidButton includeAssets={false} />
  ) : (
    <Select
      options={options}
      styles={reactSelectStyles}
      theme={themeContext}
      placeholder={disabled ? "N/A" : "Please choose account"}
      onChange={handleChange}
      isDisabled={disabled}
      defaultValue={findDefaultOption()}
    />
  );
};

export default BankAccountSelect;
